// This controller manages viewing context of a smaller image, cropped from a larger image

// image_context_view_controller.js
import ApplicationController from "./application_controller";
import {calculatePolygonDimensions} from "../lib/array_utils";
import {drawPath} from "../service_workers/service-worker";

export default class ImageContextViewController extends ApplicationController {
  static targets = [
    'imageModal',
    'draggableContext',
    'firstEntryCoordinates',
    'secondEntryCoordinates',
    'leftNavigationBtn',
    'rightNavigationBtn'
  ];

  initialize(){
    if (this.hasFirstEntryCoordinatesTarget) {
      this.firstEntryCoordinates = JSON.parse(this.firstEntryCoordinatesTarget.dataset.coordinates);
    }

    if (this.hasSecondEntryCoordinatesTarget) {
      this.secondEntryCoordinates = JSON.parse(this.secondEntryCoordinatesTarget.dataset.coordinates);
    }
    this.isSecondEntryInSameRegion = toBool(this.element.dataset['isSameRegion'])

    // this.dragContext();

    this.openImageModal();
  }

  hotKeys(e){
    const eventObject = window.event ? event : e
    const left = 37
    const right = 39
    
    if (context_modal == undefined){
      return true;
    }

    if(eventObject.keyCode == left) {
      // Click left context navigate button
      this.leftNavigationBtnTarget.click();
    }

    if(eventObject.keyCode == right) {
      // Click right context navigate button
      this.rightNavigationBtnTarget.click();
    }
  }

  dragContext() {
    dragContextElement(this.draggableContextTarget);
  }

  connect(){
    // Do nothing
  }

  openImageModal() {
    setTimeout(() => {
      if (this.hasImageModalTarget) {
        this.imageModalTarget.querySelector('.first-image')?.click(); // Dead Code Added new openImageModalAjaxCall
        this.imageModalTarget.querySelector('.context-first-image')?.click();
      }
    }, 200);
  }

  closeImageModal() {
    let contextModalId;
    contextModalId = 'context-image-modal';

    const modalElement = document.getElementById(contextModalId);
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
    const backdrops = document.querySelectorAll('.modal-backdrop');
    backdrops.forEach((backdrop) => backdrop.remove());
  }

  // This method changes the image in the Full View section while typing (ottoman or latin), highligh and slice steps,
  // It helps in seeing the entry in the region, column and page. It also highlights the current entry in the image
  changeMainImage(e) {
    if (!this.hasImageModalTarget) {
      return;
    }
    e.target.classList.add('active');

    this.imageModalTarget.querySelectorAll('.modal-img').forEach(image => image.setAttribute('hidden', null))
    this.imageModalTarget.querySelectorAll('.btn-img').forEach(btn => btn.classList.remove('active'))
    this.imageModalTarget.querySelector('.modal-body').querySelectorAll('canvas').forEach(c => c.remove());

    let canvas;
    let offsetX = 0, offsetY = 0;
    // obeject related image on modal*(first Image)
    if (this.hasFirstEntryCoordinatesTarget) {
      // active firstimages in modal
      let activeImage = this.imageModalTarget.querySelector('.' + e.target.getAttribute('data-image-key'))
      activeImage.removeAttribute('hidden')

      // created canvas for first image modal
      canvas = document.createElement('canvas');
      canvas.width = activeImage.clientWidth;
      canvas.height = activeImage.clientHeight;

      if (activeImage.dataset.coordinates) {
        ({ minX: offsetX, minY: offsetY } = calculatePolygonDimensions(JSON.parse(activeImage.dataset.coordinates)));
      }

      let points = this.firstEntryCoordinates.map(point => ({
        x: (point.x - offsetX) * activeImage.clientWidth / activeImage.naturalWidth,
        y: (point.y - offsetY) * activeImage.clientWidth / activeImage.naturalWidth
      }));
      this.imageModalTarget.querySelector('.first-image-canvas').append(canvas);

      // draw points on first image canvas
      let context = canvas.getContext('2d');
      drawPath({ context, points, closePath: true });
      context.stroke();

      if (this.isSecondEntryInSameRegion && this.hasSecondEntryCoordinatesTarget) {
        points = this.secondEntryCoordinates.map(point => ({
          x: (point.x - offsetX) * activeImage.clientWidth / activeImage.naturalWidth,
          y: (point.y - offsetY) * activeImage.clientWidth / activeImage.naturalWidth
        }));
        drawPath({ context, points, closePath: true });
        context.stroke();
      }
      context.closePath();
      ({ minY: offsetY } = calculatePolygonDimensions(points));
    }

    // obeject related image on modal*(seond Image)
    if (!this.isSecondEntryInSameRegion && this.hasSecondEntryCoordinatesTarget) {
      // active second mages on modal
      const activeImage = this.imageModalTarget.querySelectorAll('.' + e.target.getAttribute('data-image-key'))[1]
      activeImage.removeAttribute('hidden')

      // created canvas for second image modal
      canvas = document.createElement('canvas');
      canvas.width = activeImage.clientWidth;
      canvas.height = activeImage.clientHeight;

      if (activeImage.dataset.coordinates) {
        ({ minX: offsetX, minY: offsetY } = calculatePolygonDimensions(JSON.parse(activeImage.dataset.coordinates)));
      }

      this.imageModalTarget.querySelector('.second-image-canvas').append(canvas);

      const points = this.secondEntryCoordinates.map(point => ({
        x: (point.x - offsetX) * activeImage.clientWidth / activeImage.naturalWidth,
        y: (point.y - offsetY) * activeImage.clientWidth / activeImage.naturalWidth
      }));

      // draw points on second image canvas
      const context = canvas.getContext('2d');
      drawPath({ context, points, closePath: true });
      context.stroke();
      context.closePath();
      ({ minY: offsetY } = calculatePolygonDimensions(points));
    }

    setTimeout(() => {
      this.imageModalTarget.querySelector('.modal-body').scroll({ top: offsetY - 10, behavior: 'smooth' });
    });

    e.stopPropagation();
    e.preventDefault();
  }
}


// TODO: improve/modularize draggable functionality
// These methods help drag and drop of context modal 
const dragContextElement = (elmnt) => {
  var saveMouseUp = null;
  var saveMouseMove = null;

  if (elmnt) {
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;

    e.preventDefault();
    // get the mouse cursor position at startup:
    saveMouseUp = document.onmouseup;
    saveMouseMove = document.onmousemove;

    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();

    // set the element's new position:
    elmnt.style.top = (e.clientY - 30) + "px";
    elmnt.style.left = (e.clientX - (elmnt.offsetWidth / 2)) + "px";
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = saveMouseUp;
    document.onmousemove = saveMouseMove;
  }
}
