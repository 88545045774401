// image_context_view_called_controller.js
import ApplicationController from "./application_controller";

export default class ImageContextViewCallerController extends ApplicationController {

  initialize() {
    // To handle multiple modals on a single page
    this.imageViewModalId = this.element.dataset['modalId'] || 'imageModal'
  }

  openImageModal() { // Dead Code Added new openImageModalAjaxCall
    this.imageContextController().openImageModal();
  }

  imageContextController(){ // Dead Code Added new openImageModalAjaxCall
    let imageModalElement = document.querySelector(`#${this.imageViewModalId}`);
    let imageContextViewController = this.application.getControllerForElementAndIdentifier(
      imageModalElement, "image-context-view"
    );
    return imageContextViewController;
  }

  // For page_set
  openCropperContextAjaxCall(e) {
    const data = {
      object_id: e.currentTarget.dataset.objectId,
      page_set_id: e.currentTarget.dataset.pageSetId,
      stage: e.currentTarget.dataset.stage,
      object_stage: e.currentTarget.dataset.objectStage
     }
    
    $.ajax({
      url: `/page_set/context/show_item_context`,
      type: 'GET',
      data: data
    });
  }

  openImageModalAjaxCall(e){
    let data = {};
    if (e.currentTarget.dataset.contextCallFromPreview) {
      let selectedItem = document.querySelector('.js-preview-tab-content.active').querySelector('.js-preview-active-item-with-link.active');//get the current selected preview item
      data = {
        object_id:   selectedItem.getAttribute('data-item-id'),
        page_set_id: selectedItem.getAttribute('data-page-set-id')

      }
    }
    else {
      data = {
        object_id:    e.currentTarget.dataset.itemId,
        page_set_id: e.currentTarget.dataset.pageSetId
      }
    }
    data['typing'] = 'true'

    $.ajax({
      url: '/page_set/context/show_item_context',
      type: 'GET',
      data: data
    });
  }
}
