import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  populateCurrentSpellingInLinkageModal(e){
    const spellingRelationIcon = e.currentTarget;
    const spellingContainer = spellingRelationIcon.closest('.js-entries-input');
    const currentPrimarySpelling = spellingContainer.querySelector('.js-spelling-field').value;
    const primarySpellingsFields = spellingContainer.querySelectorAll('.js-current-word-primary-spelling');

    primarySpellingsFields.forEach(spellingField => {
      spellingField.textContent = currentPrimarySpelling;
    });

  }
  
  openEntryWordLinkageSourcesModal(e) {
    e.preventDefault();

    // Get the modal ID from the button's data-bs-target
    let modalId = e.currentTarget.getAttribute("data-bs-target");

    let modalElement = document.querySelector(modalId);

    // Open the second modal manually
    const sourcesModal = new bootstrap.Modal(modalElement)
    modalElement.querySelector('.modal-body').classList.add("border", "border-2", "border-dark")
    sourcesModal.show();
  }
}