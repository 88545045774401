import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  static targets = [
    'alternativeHistoriesModal',
    'saveButton'
  ];

  initialize() {
    window['spelling_sources'] = this;
    //this.#assignDefaultValues()
    this.addShowHideListenersToLatinHistoryModal();
  }

  // #assignDefaultValues(){
  //   this.isLatinHistoryModalOpen = false;
  // }
  updateButtonText() {
    this.saveButtonTarget.innerHTML = `Saved <i class="feather-check sm-icon" data-feather="check"></i>`;
    this.saveButtonTarget.classList.add('sources-saved-btn');
    
    feather.replace();

    setTimeout(() => {
      this.saveButtonTarget.textContent = "Save";
      this.saveButtonTarget.classList.remove('sources-saved-btn');
    }, 2000);
  }

  addShowHideListenersToLatinHistoryModal() {
    const modalElement = this.alternativeHistoriesModalTarget;
    modalElement.addEventListener('show.bs.modal', () => {
      isLatinHistoryModalOpen = true;
      // console.log("Modal is now open");
      // console.log(this.isLatinHistoryModalOpen)
    });

    modalElement.addEventListener('hide.bs.modal', () => {
      isLatinHistoryModalOpen = false;
      // console.log("Modal is now closed");
      // console.log(this.isLatinHistoryModalOpen)
    });		
  }
}
